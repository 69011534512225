.contactPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.contactPage .row {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

.map {
  align-self: flex-start;
}

.contact-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-inputs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact-form form {
  animation: animform 1.3s ease forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes animform {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform-origin: top;
  }
}

.contact-form h1 {
  color: #2d88ff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;

  animation: title 1.5s ease-out forwards;
  font-size: 55px;
  margin-bottom: 30px;
}

@keyframes title {
  from {
    transform: translateY(-300px);
  }
  to {
    transform-origin: top;
  }
}

.contact-form h1 span {
  display: inline-block;
}

.contact-form h1 span:hover {
  animation: letter 0.6s ease-out forwards;
}

@keyframes letter {
  0% {
    transform: scale(1.2, 0.7);
  }
  40% {
    transform: scale(1.2, 0.7);
  }
  60% {
    transform: scale(0.8, 1.2);
  }
  80% {
    transform: scale(1.2, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}

.contact-form p {
  font-size: 16px;
  color: #fff;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 55px;
  width: 420px;
}

.contact-form input {
  display: block;
  margin-top: 15px;
  width: 470px;
  background: #2b2b2b;
  height: 40px;
  font-size: 16px;
  color: #8d8d8d;
  padding: 0 20px;
  border: 0;
  outline-color: #2d88ff;
}

.contact-form textarea {
  width: 470px;
  height: 130px;
  margin-top: 15px;
  background: #2b2b2b;
  font-size: 16px;
  color: #8d8d8d;
  padding: 10px 20px;
  border: 0;
  outline-color: #2d88ff;
}

.contact-form button {
  color: #2d88ff;
  font-size: 11px;
  background-color: #1d1d1d;
  letter-spacing: 2px;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  width: 75px;
  text-align: center;
  padding: 7px 9px;
  border: 1px solid #2d88ff;
  border-radius: 5%;
  margin-top: 18px;
  margin-left: 390px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-form button:hover {
  color: #1d1d1d;
  background-color: #2d88ff;
}

.card {
  position: absolute;
  width: 220px;
  height: 110px;
  color: #fff;
  background: #000;
  top: 180px;
  right: 500px;
  line-height: 6px;
  font-size: 15px;
  padding: 10px;
  padding-top: 15px;
  z-index: 4;
  font-family: "Open Sans", "Helvetica", Arial, sans-serif;
  animation: card 1s ease-out forwards;
  opacity: 0;
}

@keyframes card {
  from {
    transform: translateY(200px);
  }
  to {
    transform: translateY(transform-origin);
    opacity: 1;
  }
}

@media (max-width: 1560px) {
  .card {
    position: sticky;
  }
}

@media (max-width: 1300px) {
  .contact-form h1 {
    font-size: 35px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .contact-form p {
    font-size: 15px;
    margin-bottom: 40px;
    width: 370px;
    margin-left: 10px;
  }

  .contact-form input {
    width: 370px;
  }

  .contact-form textarea {
    width: 370px;
  }
  .contact-form button {
    color: #2d88ff;
    margin-top: 12px;
    margin-left: 300px;
  }
}

@media (max-width: 1100px) {
  .map {
    display: none;
  }
  .contact-form h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }
  .contact-form p {
    font-size: 16px;
    margin-bottom: 40px;
    width: 370px;
  }

  .contact-form input {
    width: 470px;
  }

  .contact-form textarea {
    width: 470px;
  }
  .contact-form button {
    color: #2d88ff;
    margin-top: 12px;
    margin-left: 390px;
  }

  .contactPage {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .contact-form h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .contact-form p {
    font-size: 16px;
    margin-bottom: 20px;
    width: 330px;
    margin-left: 20px;
  }

  .contact-form input {
    width: 95%;
  }

  .contact-form textarea {
    width: 95%;
  }
  .contact-form button {
    color: #2d88ff;
    margin-top: 12px;
    margin-left: 300px;
  }
}

@media (max-width: 425px) {
  .contact-form h1 {
    margin-bottom: 30px;
    margin-left: 40px;
  }
  .contact-form p {
    font-size: 14px;
    margin-bottom: 20px;
    width: 250px;
    margin-left: 40px;
  }

  .contact-form input {
    width: 80%;
  }

  .contact-form textarea {
    width: 80%;
  }

  .contact-form button {
    display: block;
    padding: 5px;
    width: 50px;
    margin-left: 240px;
  }
}
