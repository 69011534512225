/* xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide) */

html {
  height: 100%;
}

body {
  background-color: #1d1d1d !important;
  margin: 0;
  padding: 0;
  cursor: default;
  height: 100%;
}
#root {
  height: 100%;
}

.app {
  min-height: 100%;
  width: 100%;
  display: flex;
}

.main-content {
  width: calc(100% - 65px);
  position: relative;
  left: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content > div {
  height: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.skills-wrapper {
  height: auto;
  flex-grow: initial;
}

.flexup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.wrapper .map-up {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: normal;
  width: 100%;
}

.wrapper .left-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrapper .right-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrapper .map-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.wrapper .flex-last {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1400px) {
  .wrapper .left-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media (max-width: 1300px) {
  .wrapper .left-skills {
    margin-left: 0px;
  }
}

@media (max-width: 992px) {
  .wrapper .left-content {
    justify-content: center;
  }
}

@media (max-width: 771px) {
  .flexup {
    justify-content: center;
  }
}

@media (max-width: 499px) {
  .style-home {
    /* height: 500px; */
    justify-content: center;
  }

  /* .flexup {
    height: 720px;
  } */
}

/* loader */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 400px auto;
}

.loader:before,
.loader:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  background-color: #2d88ff;
  animation: squaremove 1s ease-in-out infinite;
}

.loader:after {
  bottom: 0;
  animation-delay: 0.5s;
}

@keyframes squaremove {
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    -ms-transform: translate(0, 0) rotate(0);
    -o-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }

  25% {
    -webkit-transform: translate(40px, 40px) rotate(45deg);
    -ms-transform: translate(40px, 40px) rotate(45deg);
    -o-transform: translate(40px, 40px) rotate(45deg);
    transform: translate(40px, 40px) rotate(45deg);
  }

  50% {
    -webkit-transform: translate(0px, 80px) rotate(0deg);
    -ms-transform: translate(0px, 80px) rotate(0deg);
    -o-transform: translate(0px, 80px) rotate(0deg);
    transform: translate(0px, 80px) rotate(0deg);
  }

  75% {
    -webkit-transform: translate(-40px, 40px) rotate(45deg);
    -ms-transform: translate(-40px, 40px) rotate(45deg);
    -o-transform: translate(-40px, 40px) rotate(45deg);
    transform: translate(-40px, 40px) rotate(45deg);
  }
}
