.homePage {
  animation: fade 1s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.home-left h1 {
  color: #fff;
  font-size: 66px;
  line-height: 65px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;
}

.home-left h1 span {
  display: inline-block;
  position: relative;
  animation: jump 0.3s ease-out forwards;
}

.home-left h1 span:nth-child(1) {
  animation-delay: 1.1s;
}
.home-left h1 span:nth-child(2) {
  animation-delay: 1.2s;
}
.home-left h1 span:nth-child(3) {
  animation-delay: 1.3s;
}
.home-left h1 span:nth-child(4) {
  animation-delay: 1.4s;
}
.home-left h1 span:nth-child(5) {
  animation-delay: 1.5s;
}
.home-left h1 span:nth-child(6) {
  animation-delay: 1.6s;
}
.home-left h1 span:nth-child(7) {
  animation-delay: 1.7s;
}
.home-left h1 span:nth-child(8) {
  animation-delay: 1.8s;
}
.home-left h1 span:nth-child(9) {
  animation-delay: 1.9s;
}
.home-left h1 span:nth-child(10) {
  animation-delay: 2s;
}
.home-left h1 span:nth-child(11) {
  animation-delay: 2.1s;
}
.home-left h1 span:nth-child(12) {
  animation-delay: 2.2s;
}

@keyframes jump {
  0% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

.home-left h1 span:hover {
  color: #2d88ff;
  animation: letter 0.6s ease-out forwards;
  cursor: default;
}

@keyframes letter {
  0% {
    transform: scale(1.2, 0.7);
  }
  40% {
    transform: scale(1.2, 0.7);
  }
  60% {
    transform: scale(0.8, 1.2);
  }
  80% {
    transform: scale(1.2, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}

.home-left h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 3px;
  -webkit-animation: myanim2 1s 1.8s backwards;
  animation: myanim2 1s 1.8s backwards;
}

.home-left button {
  color: #2d88ff;
  font-size: 13px;
  background-color: #1d1d1d;
  letter-spacing: 4px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #2d88ff;
  margin-top: 25px;
  cursor: pointer;
  transition: 0.3s;
}

.home-left button:hover {
  color: #1d1d1d;
  background-color: #2d88ff;
}

.homePage .home-right {
  transform-style: preserve-3d;
  width: 300px;
  height: 300px;
  perspective: 6000px;
  perspective-origin: 50% 50%;
}

.home-right img {
  position: absolute;
  opacity: 0.5;
  width: 300px;
  height: 300px;
}

.home-right img:nth-child(1) {
  transform: rotateY(0deg) translateZ(150px);
}
.home-right img:nth-child(2) {
  transform: rotateY(90deg) translateZ(150px);
}
.home-right img:nth-child(3) {
  transform: rotateY(180deg) translateZ(150px);
}
.home-right img:nth-child(4) {
  transform: rotateY(-90deg) translateZ(150px);
}
.home-right img:nth-child(5) {
  transform: rotateX(90deg) translateZ(150px);
}
.home-right img:nth-child(6) {
  transform: rotateX(-90deg) translateZ(150px);
}

@keyframes loop {
  from {
    transform: rotateX(0deg) rotateY(0deg);
  }
  to {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

.home-right {
  animation: loop 13s linear infinite;
}

@media (max-width: 1300px) {
  .home-left h1 {
    font-size: 50px;
    line-height: 53px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 300;
  }
}
@media (max-width: 992px) {
  .home-left {
    margin-bottom: 150px;
  }
}

@media (max-width: 500px) {
  /* .home-right {
    display: none;
  } */
  .home-left {
    padding-top: 20px;
    margin-left: 20px;
    margin-bottom: 80px;
  }
  .home-left h1 {
    font-size: 35px;
    line-height: 53px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 300;
  }

  .home-right img {
    position: absolute;
    opacity: 0.5;
    width: 180px;
    height: 180px;
  }

  .home-right img:nth-child(1) {
    transform: rotateY(0deg) translateZ(90px);
  }
  .home-right img:nth-child(2) {
    transform: rotateY(90deg) translateZ(90px);
  }
  .home-right img:nth-child(3) {
    transform: rotateY(180deg) translateZ(90px);
  }
  .home-right img:nth-child(4) {
    transform: rotateY(-90deg) translateZ(90px);
  }
  .home-right img:nth-child(5) {
    transform: rotateX(90deg) translateZ(90px);
  }
  .home-right img:nth-child(6) {
    transform: rotateX(-90deg) translateZ(90px);
  }
}
