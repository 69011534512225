.about-left {
  padding-left: 100px;
}

.about-left h1 {
  color: #2d88ff;
  font-size: 50px;
  line-height: 53px;
  font-family: "Ranga", cursive;
  font-weight: 350;
  letter-spacing: 2px;
  margin-bottom: 35px;
}

.about-left h1 span {
  display: inline-block;
  animation: bounce 0.6s ease-out backwards;
}

.about-left h1 span:nth-child(1) {
  animation-delay: 0.1s;
}
.about-left h1 span:nth-child(2) {
  animation-delay: 0.2s;
}
.about-left h1 span:nth-child(3) {
  animation-delay: 0.3s;
}
.about-left h1 span:nth-child(4) {
  animation-delay: 0.4s;
}
.about-left h1 span:nth-child(5) {
  animation-delay: 0.5s;
}
.about-left h1 span:nth-child(6) {
  animation-delay: 0.6s;
}
.about-left h1 span:nth-child(7) {
  animation-delay: 0.7s;
}
.about-left h1 span:nth-child(8) {
  animation-delay: 0.8s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }
  30% {
    opacity: 0;
    transform: scale(1.4, 1.4);
  }
  70% {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
.about-left p {
  color: #fff;
  font-family: "Ranga", cursive;
  line-height: 35px;
  letter-spacing: 1px;
  animation: delay 3s linear;
  font-size: 28px;
}

@keyframes delay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.about-right img {
  transform-origin: 10px 10px;
  width: 430px;
  opacity: 0.8;
  animation: move 1.3s ease-out forwards;
}

@keyframes move {
  0% {
    transform: translateY(-700px);
  }
  40% {
    transform: translateY(100px);
  }
  75% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1250px) {
  .about-left {
    padding-left: 50px;
  }
  .about-left h1 {
    font-size: 40px;
  }
  .about-left p {
    line-height: 35px;
    font-size: 25px;
  }

  .about-right img {
    height: 100%;
    width: 380px;
  }
}

@media (max-width: 991px) {
  .about-left {
    text-align: center;
    padding: 0 100px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  .about-left h1 {
    font-size: 35px;
  }

  .about-page {
    background-image: url(../img/hossam.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .about-page .row {
    flex: 1;
    margin: 0;
  }
  .about-page .row .col-lg-5 {
    height: 100%;
    padding: 0;
  }
  .about-right {
    display: none;
  }
}

@media (max-width: 560px) {
  .about-left p {
    line-height: 35px;
    font-size: 20px;
  }

  .about-left h1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 460px) {
  .about-left p {
    line-height: 25px;
  }

  .about-left h1 {
    font-size: 28px;
    margin-bottom: 0;
  }
}
