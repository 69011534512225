.header {
  color: #2d88ff;
  animation: portfolio 1.2s ease-out forwards;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 20px;
}

@keyframes portfolio {
  from {
    transform: translateY(-300px);
  }
  to {
    transform-origin: top;
  }
}
.carousel-inner {
  width: 80%;
  margin: 0 auto;
}

.carousel-indicators {
  bottom: -100px;
}
.carousel-indicators li {
  background-color: #fff;
  height: 8px;
  opacity: 0.6;
}

.project-overview {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  /* max-height: 470px;
  overflow: auto; */
}
.some-projects {
  color: white;
  font-family: "Caveat", cursive;
}
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.row {
  display: flex;
  align-items: center;
}

.project-overview .overview {
  border-bottom: 2px solid #2d88ff;
  max-width: 50%;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

@media (max-width: 992px) {
  .project-overview {
    display: none;
  }
}

@media (max-width: 425px) {
  .carousel-indicators li {
    height: 9px;
    width: 16px;
  }
}
