.skillsPage {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.skillsPage h1 {
	color: #2d88ff;
	text-align: center;
	animation: skill 1s ease-out forwards;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
@keyframes skill {
	from {
		transform: translateY(-300px);
	}
	to {
		transform-origin: top;
	}
}

.skills-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.skills-left img {
	width: 50%;
	height: 50%;
	margin-bottom: 10px;
	animation: flipme 1s ease-out forwards;
}
@keyframes flipme {
	0% {
		transform: rotateY(180deg);
		opacity: 0;
	}
	100% {
		transform: rotateY(0deg);
		opacity: 1;
	}
}

.skills-left .detail {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.skills-left .detail h4 {
	color: #2d88ff;
	text-align: center;
}

.skills-left .detail p {
	color: #fff;
	text-align: center;
	font-size: 15px;
	width: 250px;
}

.skills-right {
	animation: appear 0.5s linear forwards;
}

@keyframes appear {
	0% {
		transform: translateX(400px);
	}
	100% {
		transform-origin: right;
	}
}

.skills-right .bar {
	background-color: #333;
	width: 550px;
	height: 28px;
	margin-bottom: 20px;
	align-items: flex-end;
	display: flex;
	font-weight: bold;
}

strong {
	color: #f0f0f0;
}

.bar .bar-fill .course {
	width: 110px;
	height: 28px;
	background-color: #181818;
	color: #fff;
	display: flex;
	justify-content: center;
}

div.bar:nth-child(1) .bar-fill {
	width: 90%;
	height: 28px;
	animation: shift 1.4s ease-out 0.6s forwards;
}

div.bar:nth-child(2) .bar-fill {
	width: 90%;
	height: 28px;
	animation: shift 1.6s ease-out 0.6s forwards;
}

div.bar:nth-child(3) .bar-fill {
	width: 90%;
	height: 28px;
	animation: shift 1.2s ease-out 0.6s forwards;
}

div.bar:nth-child(4) .bar-fill {
	width: 90%;
	height: 28px;
	animation: shift 1.5s ease-out 0.6s forwards;
}

div.bar:nth-child(5) .bar-fill {
	width: 80%;
	height: 28px;
	animation: shift 1.1s ease-out 0.6s forwards;
}

div.bar:nth-child(6) .bar-fill {
	width: 80%;
	height: 28px;
	animation: shift 1.4s ease-out 0.6s forwards;
}

div.bar:nth-child(7) .bar-fill {
	width: 80%;
	height: 28px;
	animation: shift 1.2s ease-out 0.6s forwards;
}

div.bar:nth-child(8) .bar-fill {
	width: 80%;
	height: 28px;
	animation: shift 1.5s ease-out 0.6s forwards;
}
div.bar:nth-child(9) .bar-fill {
	width: 70%;
	height: 28px;
	animation: shift 1.5s ease-out 0.6s forwards;
}
div.bar:nth-child(10) .bar-fill {
	width: 70%;
	height: 28px;
	animation: shift 1.5s ease-out 0.6s forwards;
}
@keyframes shift {
	0% {
		width: 10px;
	}
	100% {
		transform-origin: right;
		background-color: #2d88ff;
	}
}

.bar .precent {
	display: initial;
	margin-left: 12px;
	animation: precent 1s forwards;
}

@keyframes precent {
	0% {
		display: none;
	}
	80% {
		display: none;
	}
	100% {
		display: none;
	}
}

@media (max-width: 1200px) {
	.skills-right .bar {
		width: 440px;
	}

	.skills-left .detail p {
		font-size: 15px;
		width: 180px;
	}
}

@media (max-width: 992px) {
	.skills-left img {
		width: 30%;
		height: 30%;
	}
	.skills-right .bar {
		width: 540px;
	}

	.skillsPage {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.skills-right .bar {
		width: 440px;
	}
}

@media (max-width: 770px) {
	.skills-left img {
		width: 24%;
		height: 24%;
	}

	.skills-left {
		display: none;
	}

	.wrapper .row {
		display: table-column;
	}
}

@media (max-width: 500px) {
	.bar .precent {
		color: #1d1d1d;
	}
	.skillsPage h1 {
		font-size: 30px;
	}
	.skills-right .bar {
		width: 300px;
	}
}
